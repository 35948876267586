import { useMemo } from 'react'

import { MapContainer, TileLayer } from 'react-leaflet'

import { GeoJSON } from 'react-leaflet'

import L from 'leaflet'

import { Backdrop, CircularProgress } from '@mui/material'
import { styleTracksRouteGuides } from './TracksOnMap/Styled'
import { useFetchTrackRouteGuides } from '../hooks/useFetchTrackRouteGuides'
import { backdropStyle } from '../styles/BackDropStyle'
import { useUserLocation } from '../hooks/useUserLocation'
import { useFeatureAdditionalMaps } from '../hooks/useFeatureAdditionalMaps'

const RouteGuides = () => {
  const { lat, long, isLoading, initMap } = useUserLocation()

  const { routeGuidesTracks } = useFetchTrackRouteGuides()
  const { onEachFeature } = useFeatureAdditionalMaps()

  const canvasRenderer = useMemo(() => L.canvas({ tolerance: 10 }), [])

  const showMap = useMemo(() => Boolean(initMap && routeGuidesTracks), [initMap, routeGuidesTracks])

  return (
    <div className='wraper-box'>
      {(isLoading || !showMap) && (
        <Backdrop sx={backdropStyle} open={true}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}

      {showMap && (
        <MapContainer id='leaflet-map' center={[lat, long]} zoom={7} zoomControl={true} scrollWheelZoom={false} renderer={canvasRenderer}>
          <TileLayer
            minZoom={7}
            maxZoom={12}
            attribution="Images sourced from <a href='https://www.linz.govt.nz/' target='_blank' title='LINZ'>LINZ</a>"
            url='https://tiles-cdn.koordinates.com/services;key=78ddbbf18792437baac11a81c1805f72/tiles/v4/layer=50798/EPSG:3857/{z}/{x}/{y}.png'
          />
          <TileLayer
            minZoom={13}
            maxZoom={16}
            attribution=''
            url='https://tiles-cdn.koordinates.com/services;key=78ddbbf18792437baac11a81c1805f72/tiles/v4/layer=50767/EPSG:3857/{z}/{x}/{y}.png'
          />

          {routeGuidesTracks?.features?.map((item, index) => {
            return <GeoJSON key={index} data={item} style={styleTracksRouteGuides} onEachFeature={onEachFeature} />
          })}
        </MapContainer>
      )}
    </div>
  )
}

export default RouteGuides
