const backdropStyle = {
  color: 'black',
  zIndex: 100,
  backgroundColor: '#eeeeee',
  display: 'flex',
  flexDirection: 'column'
}

export { backdropStyle }
export default backdropStyle
