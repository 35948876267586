/* eslint-disable no-undef */
import { useEffect, useState, useCallback, useMemo } from 'react'
import pako from 'pako'

const useTrackData = () => {
  const [completedTracks, setCompletedTracks] = useState(null)
  const [wishlistTracks, setWishlistTracks] = useState(null)
  const [docTracks, setDocTracks] = useState(null)

  // Fetch the JSON data from the completed tracks.
  const fetchComplitedTracks = useCallback(async () => {
    try {
      const response = await fetch(process.env.PUBLIC_URL + '/data-tracks/combined.json.gz')

      // Check if the response is ok
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`)
      }

      // Read the compressed Brotli data as a stream
      const compressedData = await response.arrayBuffer()

      // Decompress the Brotli data using a TextDecoder
      const decompressedData = new TextDecoder().decode(pako.ungzip(new Uint8Array(compressedData)))

      // Parse the decompressed JSON string
      const jsonData = JSON.parse(decompressedData)

      // Set the decompressed data to state
      setCompletedTracks(jsonData)
    } catch (error) {
      console.error('Error fetching and decompressing the completed tracks:', error)
    }
  }, [])

  // Fetch the JSON data from the wishlist tracks.
  const fetchWishlistTracks = useCallback(async () => {
    try {
      const response = await fetch(process.env.PUBLIC_URL + '/data-tracks/wishlist.json.gz')

      // Check if the response is ok
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`)
      }

      // Read the compressed Brotli data as a stream
      const compressedData = await response.arrayBuffer()

      // Decompress the Brotli data using a TextDecoder
      const decompressedData = new TextDecoder().decode(pako.ungzip(new Uint8Array(compressedData)))

      // Parse the decompressed JSON string
      const jsonData = JSON.parse(decompressedData)

      // Set the decompressed data to state
      setWishlistTracks(jsonData)
    } catch (error) {
      console.error('Error fetching and decompressing the wishlist tracks:', error)
    }
  }, [])

  // Fetch and decode Brotli-compressed JSON for DOC tracks
  const fetchDocTracks = useCallback(async () => {
    try {
      const response = await fetch(process.env.PUBLIC_URL + '/data-tracks/doc.json.gz')

      // Check if the response is ok
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`)
      }

      // Read the compressed Brotli data as a stream
      const compressedData = await response.arrayBuffer()

      // Decompress the Brotli data using a TextDecoder
      const decompressedData = new TextDecoder().decode(pako.ungzip(new Uint8Array(compressedData)))

      // Parse the decompressed JSON string
      const jsonData = JSON.parse(decompressedData)

      // Set the decompressed data to state
      setDocTracks(jsonData)
    } catch (error) {
      console.error('Error fetching and decompressing the DOC tracks:', error)
    }
  }, [])

  const tracksLoaded = useMemo(() => Boolean(completedTracks && wishlistTracks && docTracks), [completedTracks, wishlistTracks, docTracks])

  useEffect(() => {
    fetchComplitedTracks()
  }, [fetchComplitedTracks])

  useEffect(() => {
    fetchWishlistTracks()
  }, [fetchWishlistTracks])

  useEffect(() => {
    fetchDocTracks()
  }, [fetchDocTracks])

  return { completedTracks, wishlistTracks, docTracks, tracksLoaded }
}

export { useTrackData }
export default useTrackData
