const styleVisited = {
  color: '#000000',
  weight: 3,
  opacity: 0.7
}

const styleWishlist = {
  color: 'blue',
  weight: 3,
  opacity: 0.7
}

const styleDocTracks = {
  color: '#BF40BF',
  weight: 3,
  opacity: 1,
  dashArray: '10 10'
}

const styleTracksCTC = {
  color: 'red',
  weight: 3,
  opacity: 0.7
}

const styleTracksRouteGuides = {
  color: 'blue',
  weight: 3,
  opacity: 0.7
}

export { styleVisited, styleWishlist, styleDocTracks, styleTracksCTC, styleTracksRouteGuides }
