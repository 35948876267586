export const userLocationStyle = {
  position: 'absolute',
  top: 0,
  left: '50%',
  color: '#FFFFFF',
  backgroundColor: 'rgba(51, 51, 51, 0.5)',
  transform: 'translate(-50%, 0)',
  zIndex: 10000,
  display: 'flex',
  alignItems: 'center',
  padding: '2px 5px',
  borderRadius: '0 0 3px 3px',
  fontSize: '10px'
}

export const iconStyle = { fontSize: '10px', mr: 0.5 }
