import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

const siteDescription = 'My completed trails and wishlist for the future hikes.'

const MetaHeader = ({ image, trackName }) => {
  const pageTitle = useMemo(() => (trackName ? `TrailTreks - ${trackName}` : 'TrailTreks'), [trackName])
  const pageImage = useMemo(() => (image ? `https://trailtreks.nz/photos/${image}` : 'https://trailtreks.nz/photos/thumbnail.jpg'), [image])
  const pageUrl = useMemo(
    () => (trackName ? `https://trailtreks.nz/track/${trackName?.toLowerCase()?.replaceAll(' ', '-')}` : 'https://trailtreks.nz/'),
    [trackName]
  )

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name='description' content={siteDescription} />

      <meta property='og:url' content={pageUrl} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={pageTitle} />
      <meta property='og:site_name' content='TrailTreks' />
      <meta property='og:description' content={siteDescription} />
      <meta property='og:image' content={pageImage} />

      <meta name='twitter:card' content='summary_large_image' />
      <meta property='twitter:domain' content='trailtreks.nz' />
      <meta property='twitter:url' content={pageUrl} />
      <meta name='twitter:title' content={pageTitle} />
      <meta name='twitter:description' content={siteDescription} />
      <meta name='twitter:image' content={pageImage} />
      <meta name='twitter:creator' content='@kobiak' />
      <meta name='twitter:site' content='@kobiak' />
    </Helmet>
  )
}

MetaHeader.propTypes = {
  image: PropTypes.string,
  trackName: PropTypes.string
}

export { MetaHeader }
export default MetaHeader
