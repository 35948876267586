import { useCallback } from 'react'
import { getKms } from './functions'

const useFeatureAdditionalMaps = () => {
  const onEachFeature = useCallback((feature, layer) => {
    if (feature.properties && feature.geometry.type !== 'Point') {
      const trackName = feature?.properties?.name
      const distance = getKms(feature?.properties?.distance)
      const sourceFile = feature?.properties?.sourceFile
      const description = feature?.properties?.cmt || ''

      layer.bindTooltip(`
        <div style="width: 300px; white-space: normal;">
          <b>${trackName}</b><br>
          ${description ? `<hr>${description}<hr>` : ''}
          <span style="color: #333333;">Distance: ${distance}km</span><br>
          ${sourceFile ? sourceFile : ''}
        </div>
      `)

      layer.on({
        mouseover: (e) => {
          if (e.target.feature.geometry.type !== 'Point') {
            e.target.setStyle({
              color: 'black',
              opacity: 1
            })
            e.target.bringToFront()
            e.target.openTooltip(e.latlng)
          }
        },
        mouseout: (e) => {
          e.target.setStyle({
            color: 'blue',
            opacity: 0.8
          })
        },
        click: () => {},
        popupopen: () => {},
        popupclose: () => {}
      })
    }
  }, [])

  return { onEachFeature }
}

export { useFeatureAdditionalMaps }
export default useFeatureAdditionalMaps
