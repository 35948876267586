export const JSToCSS = (JS) => {
  let cssString = ''
  for (let objectKey in JS) {
    cssString += objectKey.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`) + ': ' + JS[objectKey] + ';\n'
  }

  return cssString
}

export const getKms = (km) => {
  return (km / 1000).toFixed(2)
}
