/* eslint-disable no-undef */
import { useCallback, useEffect, useState } from 'react'
import pako from 'pako'

const useFetchTrackRouteGuides = () => {
  const [routeGuidesTracks, setRouteGuidesTracks] = useState(null)
  // Fetch the JSON data from the Ctc tracks.
  const fetchRouteGuidesTracks = useCallback(async () => {
    try {
      const response = await fetch(process.env.PUBLIC_URL + '/data-tracks/routeguides.json.gz')

      if (!response.ok) {
        throw new Error(`Failed to fetch JSON: ${response.statusText}`)
      }

      // Read the compressed Brotli data as a stream
      const compressedData = await response.arrayBuffer()

      // Decompress the Brotli data using a TextDecoder
      const decompressedData = new TextDecoder().decode(pako.ungzip(new Uint8Array(compressedData)))

      // Parse the decompressed JSON string
      const jsonData = JSON.parse(decompressedData)

      setRouteGuidesTracks(jsonData)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    fetchRouteGuidesTracks()
  }, [fetchRouteGuidesTracks])

  return { routeGuidesTracks }
}

export { useFetchTrackRouteGuides }
export default useFetchTrackRouteGuides
