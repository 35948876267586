import { useMemo } from 'react'

import { MapContainer, TileLayer } from 'react-leaflet'

import { GeoJSON } from 'react-leaflet'

import L from 'leaflet'

import { Backdrop, CircularProgress } from '@mui/material'
import { styleTracksCTC } from './TracksOnMap/Styled'
import { useFetchTrackCTC } from '../hooks/useFetchTrackCTC'
import { useUserLocation } from '../hooks/useUserLocation'
import { backdropStyle } from '../styles/BackDropStyle'
import useFeatureAdditionalMaps from '../hooks/useFeatureAdditionalMaps'

const CTC = () => {
  const { lat, long, isLoading, initMap } = useUserLocation()

  const { ctcTracks } = useFetchTrackCTC()
  const { onEachFeature } = useFeatureAdditionalMaps()

  const canvasRenderer = useMemo(() => L.canvas({ tolerance: 10 }), [])

  const showMap = useMemo(() => Boolean(initMap && ctcTracks), [initMap, ctcTracks])

  return (
    <div className='wraper-box'>
      {(isLoading || !showMap) && (
        <Backdrop sx={backdropStyle} open={true}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}

      {showMap && (
        <MapContainer id='leaflet-map' center={[lat, long]} zoom={7} zoomControl={true} scrollWheelZoom={false} renderer={canvasRenderer}>
          <TileLayer
            minZoom={7}
            maxZoom={12}
            attribution="Images sourced from <a href='https://www.linz.govt.nz/' target='_blank' title='LINZ'>LINZ</a>"
            url='https://tiles-cdn.koordinates.com/services;key=78ddbbf18792437baac11a81c1805f72/tiles/v4/layer=50798/EPSG:3857/{z}/{x}/{y}.png'
          />
          <TileLayer
            minZoom={13}
            maxZoom={16}
            attribution=''
            url='https://tiles-cdn.koordinates.com/services;key=78ddbbf18792437baac11a81c1805f72/tiles/v4/layer=50767/EPSG:3857/{z}/{x}/{y}.png'
          />

          {ctcTracks.features.map((item, index) => (
            <GeoJSON key={index} data={item} style={styleTracksCTC} onEachFeature={onEachFeature} />
          ))}
        </MapContainer>
      )}
    </div>
  )
}

export default CTC
