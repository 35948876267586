import { useCallback, useEffect } from 'react'
import { useMap } from 'react-leaflet/hooks'
import { ElevationProfile } from '../ElevationProfile/ElevationProfile'
import PropTypes from 'prop-types'

const FlyToTrackStart = ({ coordinates }) => {
  const map = useMap()

  const flyToTrack = useCallback(
    (coor) => {
      if (coor !== null) {
        map.flyTo(coor, 12)
      }
    },
    [map]
  )

  useEffect(() => {
    flyToTrack(coordinates)
  }, [coordinates, flyToTrack])

  return <ElevationProfile />
}

FlyToTrackStart.propTypes = {
  coordinates: PropTypes.array
}

export { FlyToTrackStart }
export default FlyToTrackStart
