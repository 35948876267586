export const ElevationProfileStyles = {
  button: {
    backGroundColor: 'white'
  },
  box: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1000,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  drawer: {
    maxHeight: '20vh',
    width: '100%',
    display: 'flex'
  }
}

export default ElevationProfileStyles
