import { useEffect, useRef } from 'react'
import { useMap } from 'react-leaflet'

import L from 'leaflet'
import { useOutletContext } from 'react-router-dom'
import { elevationButtonStyle } from './Styled'
import { JSToCSS } from '../../hooks/functions'

const ElevationButton = ({ trackSlug }) => {
  const map = useMap()
  const addedRef = useRef(false)

  const { handleOpenElevationProfile } = useOutletContext()

  // create custom button
  const elevationProfileButton = L.Control.extend({
    // button position
    options: {
      position: 'topleft',
      className: `leaflet-bar`,
      html: '<svg  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21.85,17.47l-5-8a1,1,0,0,0-1.7,0l-1,1.63L10.86,5.5a1,1,0,0,0-1.72,0l-7,12A1,1,0,0,0,3,19H21a1,1,0,0,0,.85-1.53ZM10.45,17H4.74L10,8l2.93,5Zm2.35,0L15,13.57h0L16,11.89,19.2,17Z"/></svg>',
      style: JSToCSS(elevationButtonStyle)
    },

    // method
    onAdd: function (map) {
      if (addedRef.current !== true) {
        this._map = map
        const button = L.DomUtil.create('div')
        L.DomEvent.disableClickPropagation(button)

        button.title = `View elevation profile`
        button.innerHTML = this.options.html
        button.className = this.options.className
        button.setAttribute('style', this.options.style)

        L.DomEvent.on(button, 'click', this._clicked, this)

        addedRef.current = true

        return button
      }

      return null
    },

    _clicked: function (e) {
      L.DomEvent.stopPropagation(e)

      handleOpenElevationProfile()

      return
    }
  })

  useEffect(() => {
    // adding new button to map controll

    if (addedRef.current !== true && trackSlug) {
      map.addControl(new elevationProfileButton())
    }
  }, [map, elevationProfileButton, trackSlug])
}

export { ElevationButton }
export default ElevationButton
