import { useMemo } from 'react'

import { useUserLocation } from '../../hooks/useUserLocation'
import Box from '@mui/material/Box'
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt'
import { iconStyle, userLocationStyle } from './UserLocationStyles'

const UserLocation = () => {
  const { isLoading, lat, long } = useUserLocation()

  const showUserLocation = useMemo(() => {
    return lat && long && !isLoading ? (
      <Box sx={userLocationStyle}>
        <SatelliteAltIcon fontSize='small' sx={iconStyle} /> {lat}, {long}
      </Box>
    ) : null
  }, [isLoading, lat, long])

  return showUserLocation
}

export { UserLocation }
export default UserLocation
